export default {
    // 中
    zhCN: {
        have: '共有',
        NoticeWaitingToBeRead: '条待阅通知',
        title: '标题',
        publishing_department: '发布部门',
        status: '状态',
        release_Date: '发布日期',
        bulletin_id: '公告ID',
        publisher: '发布人',
        information_detail: '公告信息',
        notification_Title: '通知标题',
        publish_Objects: '发布对象',
        publish_Mode: '发布模式',
        publish_Content: '发布内容',
        bulletin_type: '公告分类',
        viewing_rate_statements_export: '查看率报表导出',
        report_export: '报表导出',
        related_announcements: '关联公告信息',
        related_announcements_notification: '关联公告通知',
        link_to_announcement: '链接',
        forwarding_records: '转发记录',
        vehicle_condition: '车况',
        Extreme: '极端',
        road_condition: '路况',
        climate: '气候',
        frequency: '频率',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        email_notification: '是否邮件通知',
        required_are_mis: '存在必填项未填！',
        select_publish_Objects: '选择发布对象',
        isPinned: '是否置顶',
    },
    // 英
    enUS: {
        have: 'Have',
        NoticeWaitingToBeRead: 'Notice waiting to be read',
        title: 'Title',
        publishing_department: 'Publishing department',
        status: 'Status',
        release_Date: 'Release date',
        bulletin_id: 'Bulletin ID',
        publisher: 'Publisher',
        information_detail: 'Information detail',
        notification_Title: 'Notification title',
        publish_Objects: 'Release to',
        publish_Mode: 'Release mode',
        publish_Content: 'Release content',
        bulletin_type: 'Bulletin type',
        viewing_rate_statements_export: 'View Rate Report Export',
        report_export: 'report Export',
        related_announcements: 'Related announcement information',
        related_announcements_notification: 'Related announcements notification',
        link_to_announcement: 'Announcement',
        forwarding_records: 'Forwarding records',
        vehicle_condition: 'Vehicle Condition',
        Extreme: 'Extreme',
        road_condition: 'Road Condition',
        climate: 'Climate',
        frequency: 'Frequency',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        email_notification: 'Email Notification',
        required_are_mis: 'Required fields are missing!',
        select_publish_Objects: 'Select publish target',
        isPinned: 'isPinned',
    },
    // 德
    deDE: {
        have: 'haben',
        NoticeWaitingToBeRead: 'Benachrichtigungen wartend auf Lektüre',
        title: 'Titel',
        publishing_department: 'Herausgebende Abteilung',
        status: 'Status',
        release_Date: 'Veröffentlichungsdatum',
        bulletin_id: 'Bulletin-ID',
        publisher: 'Veröffentlicher',
        information_detail: 'Information Detail',
        notification_Title: 'Benachrichtigungstitel',
        publish_Objects: 'Freigabe an',
        publish_Mode: 'Freigabemodus',
        publish_Content: 'Freigegebener Inhalt',
        bulletin_type: 'Ausgefallene typen',
        viewing_rate_statements_export: 'Export des Anzeigeraten-Berichts',
        report_export: 'Exportbericht',
        related_announcements: 'Verwandte Ankündigungsinformationen',
        related_announcements_notification: 'Verwandte Ankündigungsbenachrichtigung',
        link_to_announcement: 'Ankündigung',
        forwarding_records: 'Weiterleitung von Datensätzen',
        vehicle_condition: 'Fahrzeugzustand',
        Extreme: 'Extrem',
        road_condition: 'Straßenzustand',
        climate: 'Klima',
        frequency: 'Frequenz',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        email_notification: 'E-Mail-Benachrichtigung',
        required_are_mis: 'Es fehlen erforderliche Felder!',
        select_publish_Objects: 'Wählen Sie das Veröffentlichungsziel',
        isPinned: 'istAngeheftet',
    },
    // 法
    frFR: {
        have: 'Avoir',
        NoticeWaitingToBeRead: 'Notification en attente de lecture',
        title: 'Titre',
        publishing_department: 'Département',
        status: 'Statut',
        release_Date: 'Date de sortie',
        bulletin_id: 'ID du bulletin',
        publisher: 'Rédacteur',
        information_detail: 'Détails',
        notification_Title: 'Titre de la notification',
        publish_Objects: 'A destination de',
        publish_Mode: 'Mode de communication',
        publish_Content: 'Contenu',
        bulletin_type: 'Types d’annonces',
        viewing_rate_statements_export: 'Exportation du Rapport de Taux de Visualisation',
        report_export: 'Rapport d\'exportation',
        related_announcements: 'Informations sur l\'annonce connexe',
        related_announcements_notification: 'Notification d\'annonce associée',
        link_to_announcement: 'Annonce',
        forwarding_records: 'Transfert des enregistrements',
        vehicle_condition: 'État du véhicule',
        Extreme: 'Extrême',
        road_condition: 'État de la route',
        climate: 'Climat',
        frequency: 'Fréquence',
        organization_code: 'Code organisation',
        organization_name: 'Nom organisation',
        email_notification: 'Notification par e-mail',
        required_are_mis: 'Des champs obligatoires sont manquants!',
        select_publish_Objects: 'Sélectionner l\'objet de publication',
        isPinned: 'estEpingle',
    },
    // 意大利
    itIT: {
        have: 'Hai',
        NoticeWaitingToBeRead: 'notifiche in attesa di lettura',
        title: 'Titolo',
        publishing_department: 'Reparto Pubblicazione',
        status: 'Stato',
        release_Date: 'Data di Rilascio',
        bulletin_id: 'ID Bollettino',
        publisher: 'Pubblicatore',
        information_detail: 'Dettaglio Informazione',
        notification_Title: 'Titolo Notifica',
        publish_Objects: 'Destinatari',
        publish_Mode: 'Modalità di Rilascio',
        publish_Content: 'Contenuto Rilascio',
        bulletin_type: 'Tipo di notifica',
        viewing_rate_statements_export: 'Esportazione del Rapporto sul Tasso di Visualizzazione',
        report_export: 'Esporta rapporto',
        related_announcements: 'Informazioni sull\'annuncio correlato',
        related_announcements_notification: 'Notifica di annuncio correlata',
        link_to_announcement: 'Annuncio',
        forwarding_records: 'Invio dei record',
        vehicle_condition: 'Condizione del veicolo',
        Extreme: 'Estremo',
        road_condition: 'Condizione stradale',
        climate: 'Clima',
        frequency: 'Frequenza',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        email_notification: 'Notifica via e-mail',
        required_are_mis: 'Mancano dei campi obbligatori!',
        select_publish_Objects: 'Selezionare l\'oggetto di pubblicazione',
        isPinned: 'eFissato',
    },
    // 荷兰
    nlNL: {
        have: 'Hebben',
        NoticeWaitingToBeRead: 'Kennisgevingen die wachten om te worden gelezen',
        title: 'Titel',
        publishing_department: 'Uitgevende afdeling',
        status: 'Status',
        release_Date: 'Uitgiftedatum',
        bulletin_id: 'Bulletin ID',
        publisher: 'Uitgever',
        information_detail: 'Informatiedetails',
        notification_Title: 'Meldingstitel',
        publish_Objects: 'Uitgeven aan',
        publish_Mode: 'Uitgiftetype',
        publish_Content: 'Uitgeefinhoud',
        bulletin_type: 'Soort aankondiging',
        viewing_rate_statements_export: 'Exporteer Rapport van Weergavesnelheid',
        report_export: 'Exportverslag',
        related_announcements: 'Gerelateerde aankondigingsinformatie',
        related_announcements_notification: 'Gerelateerde aankondigingsmelding',
        link_to_announcement: 'Aankondiging',
        forwarding_records: 'Doorsturen van records',
        vehicle_condition: 'Voertuigconditie',
        Extreme: 'Extreem',
        road_condition: 'Wegconditie',
        climate: 'Klimaat',
        frequency: 'Frequentie',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        email_notification: 'E-mailmelding',
        required_are_mis: 'Vereiste velden ontbreken!',
        select_publish_Objects: 'Selecteer publicatiedoel',
        isPinned: 'isVastgepind',
    },
    // 越南
    viVN: {
        have: 'Tổng cộng',
        NoticeWaitingToBeRead: 'thông báo đang chờ đọc',
        title: 'Tiêu đề',
        publishing_department: 'Bộ phận xuất bản',
        status: 'Trạng thái',
        release_Date: 'Ngày phát hành',
        bulletin_id: 'Mã số bảng thông báo',
        publisher: 'Nhà xuất bản',
        information_detail: 'Thông tin chi tiết',
        notification_Title: 'Tiêu đề thông báo',
        publish_Objects: 'Phát hành tới',
        publish_Mode: 'Chế độ phát hành',
        publish_Content: 'Nội dung phát hành',
        bulletin_type: 'Loại thông báo',
        viewing_rate_statements_export: 'Xuất Báo cáo Tỷ lệ Xem',
        report_export: 'Báo cáo Xuất khẩu',
        related_announcements: 'Thông tin thông báo liên quan',
        related_announcements_notification: 'Thông báo thông báo liên quan',
        link_to_announcement: 'Thông báo',
        forwarding_records: 'Chuyển tiếp hồ sơ',
        vehicle_condition: 'Tình trạng xe',
        Extreme: 'Cực kỳ',
        road_condition: 'Tình trạng đường',
        climate: 'Khí hậu',
        frequency: 'Tần suất',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        email_notification: 'Thông báo qua email',
        required_are_mis: 'Có trường bắt buộc chưa được điền!',
        select_publish_Objects: 'Chọn đối tượng phát hành',
        isPinned: 'coGhim',
    },
    // 西班牙
    esES: {
        have: 'Total',
        NoticeWaitingToBeRead: 'Número de notificaciones pendientes de lectura',
        title: 'Título',
        publishing_department: 'Departamento de publicación',
        status: 'Estado',
        release_Date: 'Fecha de publicación',
        bulletin_id: 'ID del anuncio',
        publisher: 'Persona que publica',
        information_detail: 'Información del anuncio',
        notification_Title: 'Título de la notificación',
        publish_Objects: 'Destinatarios de la publicación',
        publish_Mode: 'Modo de publicación',
        publish_Content: 'Contenido de la publicación',
        bulletin_type: 'Categoría del anuncio',
        viewing_rate_statements_export: 'Exportar Informe de Tasa de Visualización',
        report_export: 'Informe de exportación',
        related_announcements: 'Información de anuncio relacionada',
        related_announcements_notification: 'Notificación de anuncio relacionada',
        link_to_announcement: 'Anuncio',
        forwarding_records: 'Registro de reenvío',
        vehicle_condition: 'Estado del vehículo',
        Extreme: 'Extremo',
        road_condition: 'Estado de la carretera',
        climate: 'Clima',
        frequency: 'Frecuencia',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        email_notification: 'Notificación por correo electrónico',
        required_are_mis: '¡Faltan campos obligatorios!',
        select_publish_Objects: 'Seleccionar el objetivo de publicación',
        isPinned: 'estaAnclado',
    },
    // 葡萄牙
    ptBR: {
        have: 'Têm no total',
        NoticeWaitingToBeRead: 'notificações para ler',
        title: 'Título',
        publishing_department: 'Departamento de Publicação',
        status: 'Estado',
        release_Date: 'Data de publicação',
        bulletin_id: 'ID do aviso',
        publisher: 'Editor',
        information_detail: 'Detalhes do aviso',
        notification_Title: 'Título da notificação',
        publish_Objects: 'Objetos de publicação',
        publish_Mode: 'Modo de publicação',
        publish_Content: 'Conteúdo de publicação',
        bulletin_type: 'Classificação do aviso',
        viewing_rate_statements_export: 'Exportar Relatório de Taxa de Visualização',
        report_export: 'Relatório de exportação',
        related_announcements: 'Informações de anúncio relacionadas',
        related_announcements_notification: 'Notificação de anúncio relacionada',
        link_to_announcement: 'Anúncio',
        forwarding_records: 'Registos de encaminhamento',
        vehicle_condition: 'Condição do veículo',
        Extreme: 'Extremo',
        road_condition: 'Condição da estrada',
        climate: 'Clima',
        frequency: 'Frequência',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        email_notification: 'Notificação por e-mail',
        required_are_mis: 'Campos obrigatórios estão faltando!',
        select_publish_Objects: 'Selecionar objeto de publicação',
        isPinned: 'estaFixado',
    },
    // 波兰
    plPL: {
        have: 'Posiadać',
        NoticeWaitingToBeRead: 'Oczekujące powiadomienia',
        title: 'Tytuł',
        publishing_department: 'Dział publikujący',
        status: 'Status',
        release_Date: 'Data publikacji',
        bulletin_id: 'Identyfikator biuletynu',
        publisher: 'Osoba publikująca',
        information_detail: 'Informacje szczegółowe',
        notification_Title: 'Tytuł powiadomienia',
        publish_Objects: 'Opublikowane do',
        publish_Mode: 'Tryb publikacji',
        publish_Content: 'Treść publikacji',
        bulletin_type: 'Typ biuletynu',
        viewing_rate_statements_export: 'Eksport Raportu Stopy Wyświetlania',
        report_export: 'Raport eksportu',
        related_announcements: 'Powiązane informacje o ogłoszeniach',
        related_announcements_notification: 'Powiązane powiadomienie o ogłoszeniu',
        link_to_announcement: 'Ogłoszenie',
        forwarding_records: 'Przekazywanie zapisów',
        vehicle_condition: 'Stan pojazdu',
        Extreme: 'Ekstremalny',
        road_condition: 'Stan drogi',
        climate: 'Klimat',
        frequency: 'Częstotliwość',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        email_notification: 'Powiadomienie e-mail',
        required_are_mis: 'Brakuje wymaganych pól!',
        select_publish_Objects: 'Wybierz obiekt publikacji',
        isPinned: 'jestPrzypiete',
    },
    // 土耳其
    trTR: {
        have: 'Evet.',
        NoticeWaitingToBeRead: 'Bekleyen bildirimler',
        title: 'Başlık',
        publishing_department: 'Yayım departmanı',
        status: 'Durum',
        release_Date: 'Yayınlama Tarihi',
        bulletin_id: 'Bülten numarası',
        publisher: 'Yayıncı',
        information_detail: 'Bilgi detayları',
        notification_Title: 'Bildirim başlığı',
        publish_Objects: 'Yayınla',
        publish_Mode: 'Yayınlama modu',
        publish_Content: 'Yayın içeriği',
        bulletin_type: 'Bülten tipi',
        viewing_rate_statements_export: 'Görüntüleme Oranı Raporunu Dışa Aktarma',
        report_export: 'Rapor İhracat',
        related_announcements: 'İlgili duyuru bilgileri',
        related_announcements_notification: 'İlgili duyuru bildirimi',
        link_to_announcement: 'Duyuru',
        forwarding_records: 'Kayıtları ilerleme',
        vehicle_condition: 'Araç Durumu',
        Extreme: 'Aşırı',
        road_condition: 'Yol Durumu',
        climate: 'İklim',
        frequency: 'Frekans',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        email_notification: 'E-posta Bildirimi',
        required_are_mis: 'Zorunlu alanlar eksik!',
        select_publish_Objects: 'Yayın hedefini seç',
        isPinned: 'sabitMi',
    },
    // 捷克语
    csCZ: {
        have: 'Mají',
        NoticeWaitingToBeRead: 'Oznámení čekající na přečtení',
        title: 'Název',
        publishing_department: 'Vydávání publikací',
        status: 'Status',
        release_Date: 'Datum vydání',
        bulletin_id: 'ID bulletinu',
        publisher: 'Vydavatel',
        information_detail: 'Detail informace',
        notification_Title: 'Název oznámení',
        publish_Objects: 'Uvolnění pro',
        publish_Mode: 'Režim vydání',
        publish_Content: 'Popis obsahu vydání',
        bulletin_type: 'Typ bulletinu',
        viewing_rate_statements_export: 'View Rate Report Export',
        report_export: 'report Export',
        related_announcements: 'Související oznámení',
        related_announcements_notification: 'Upozornění na související oznámení',
        link_to_announcement: 'Odkaz na oznámení',
        forwarding_records: 'Přeposílání záznamů',
        vehicle_condition: 'Stav vozidla',
        Extreme: 'Extrémní',
        road_condition: 'Stav silnice',
        climate: 'Podnebí',
        frequency: 'Frekvence',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        email_notification: 'E-mailová notifikace',
        required_are_mis: 'Chybí povinné pole!',
        select_publish_Objects: 'Vyberte cíl publikace',
        isPinned: 'jePripnuto',
    },
    // 匈牙利语
    huHU: {
        have: 'Összesen',
        NoticeWaitingToBeRead: 'Olvasatlan értesítések',
        title: 'Cím',
        publishing_department: 'Közzétevő részleg',
        status: 'Állapot',
        release_Date: 'Kiadási dátum',
        bulletin_id: 'Hirdetményazonosító',
        publisher: 'Közzétevő',
        information_detail: 'Információ részlet',
        notification_Title: 'Értesítés címe',
        publish_Objects: 'Közzétéve itt:',
        publish_Mode: 'Közzétételi üzemmód',
        publish_Content: 'Közzétett tartalom',
        bulletin_type: 'Hirdetmény típusa',
        viewing_rate_statements_export: 'Megtekintés Osztályozás Kimutatás Exportálás',
        report_export: 'Kimutatás exportálása',
        related_announcements: 'Kapcsolódó bejelentések',
        related_announcements_notification: 'Kapcsolódó bejelentések értesítése',
        link_to_announcement: 'Hivatkozás a bejelentésekre',
        forwarding_records: 'Rekordok továbbítása',
        vehicle_condition: 'Járműállapot',
        Extreme: 'Szélsőséges',
        road_condition: 'Útviszonyok',
        climate: 'Klíma',
        frequency: 'Gyakoriság',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        email_notification: 'E-mail értesítés',
        required_are_mis: 'Kötelező mezők hiányoznak!',
        select_publish_Objects: 'Válassza ki a közzétételi célt',
        isPinned: 'felFestítve',
    },
    // 俄罗斯
    ruRU: {
        have: 'Есть',
        NoticeWaitingToBeRead: 'Уведомление ожидает прочтения',
        title: 'Заголовок',
        publishing_department: 'Отдел публикации',
        status: 'Статус',
        release_Date: 'Дата выпуска',
        bulletin_id: 'Идентификатор бюллетеня',
        publisher: 'Издатель',
        information_detail: 'Детали информации',
        notification_Title: 'Заголовок уведомления',
        publish_Objects: 'Опубликовать для',
        publish_Mode: 'Режим публикации',
        publish_Content: 'Содержание публикации',
        bulletin_type: 'Тип бюллетеня',
        viewing_rate_statements_export: 'Экспорт отчетов о просмотре',
        report_export: 'Экспорт отчета',
        related_announcements: 'Связанные объявления',
        related_announcements_notification: 'Уведомление о связанных объявлениях',
        link_to_announcement: 'Ссылка на объявление',
        forwarding_records: 'Записи о пересылке',
        vehicle_condition: 'Состояние автомобиля',
        Extreme: 'Экстремальные',
        road_condition: 'Состояние дороги',
        climate: 'Климат',
        frequency: 'Частота',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        email_notification: 'Уведомление по почте',
        required_are_mis: 'Отсутствуют обязательные поля!',
        select_publish_Objects: 'Выберите объект публикации',
        isPinned: 'zakrepleno',
    },
};
